<template>
  <div>
    <r-page>
      <template #page-header>
        <h2 class="page-title">Company Index</h2>
        <r-third-level-menu></r-third-level-menu>
      </template>
      <template #page-content>
        <r-card>
          <b-table
            :data="company.data ? company.data : []"
            :loading="loading"
            paginated
            backend-pagination
            :total="company.meta ? company.meta.total : 0"
            :per-page="params.perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :current-page.sync="params.page"
            hoverable
            backend-sorting
            @sort="onSort"
            class="table-timeoff-type table-request-personal"
          >
            <b-table-column field="no" label="No" v-slot="props" sortable>
              {{ props.index + company.meta.from }}
            </b-table-column>
            <b-table-column
              field="companies.name"
              label="Company Name"
              v-slot="props"
              sortable
            >
              {{ props.row.companyName }}
            </b-table-column>
            <b-table-column
              field="payment_method"
              label="Payment Method"
              v-slot="props"
              sortable
            >
              {{ props.row.paymentMethod }}
            </b-table-column>
            <b-table-column
              field="total_users"
              label="Total Users"
              v-slot="props"
              sortable
            >
              {{ props.row.totalUsers }}
            </b-table-column>
            <template #empty>
              <div class="has-text-centered">
                No data yet, please contact us if you have any problems
                (hello@getrise.id)
              </div>
            </template>
          </b-table>
        </r-card>
      </template>
    </r-page>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: {
        data: [],
        meta: {},
      },
      loading: false,
      params: {
        perPage: 10,
        page: 1,
        orderBy: 'billings.created_at',
        orderDirection: 'desc',
      },
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$store
        .dispatch('company/IndexActiveCompany', this.params)
        .then((response) => {
          this.loading = false
          this.company.data = response.data
          this.company.meta = response.meta
        })
    },
    onPageChange(page) {
      this.params.page = page
      this.fetchData()
    },
    onSort(field, order) {
      this.params.orderBy = field
      this.params.orderDirection = order
      this.fetchData()
    },
  },
}
</script>
